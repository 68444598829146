import { FC } from "react";
import UAParser from "ua-parser-js";
import "./_game.scss";

const parser = new UAParser();
const result = parser.getResult();
const deviceType = (result.device && result.device.type) || "desktop";

export const Game: FC = () => {
  return (
    <div className="game">
      <h1>{}</h1>
      <div className="game__wrapper">
        {deviceType === "desktop" ? (
          <iframe
            className="game__embed"
            src={process.env.PUBLIC_URL + "/game/index.html"}
            title="Les frères Miller"
          ></iframe>
        ) : (
          <iframe
            className="game__embed"
            src="https://www.youtube.com/embed/kWtCT9_ML-c"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </div>
    </div>
  );
};
