import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import logo from "../../assets/st-lau_logo_w.png";
import "./_header.scss";

export const Header: FC = () => {
  return (
    <header className="header">
      <a href="#/" className="header__logo">
        <img
          src={logo}
          className="header__logo__image"
          alt="St Laurent Sachet"
        />
      </a>

      <nav className="header__nav">
        <a
          className="header__nav__item"
          href="https://juliendallairecharest.carbonmade.com/lesfreresmiller"
          target="_blank"
          rel="noreferrer"
        >
          À propos
        </a>

        <a
          className="header__nav__item"
          href="https://stlaurentsachet.bigcartel.com/"
          target="_blank"
          rel="noreferrer"
        >
          Boutique
        </a>

        <a className="header__nav__item" href="#projet">
          Projet
        </a>

        <a
          className="header__nav__item"
          href="https://m.facebook.com/stlaurentsachet/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={["fab", "facebook"]} />
        </a>
        <a
          className="header__nav__item"
          href="https://st-laurentsachet.bandcamp.com/releases"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={["fab", "bandcamp"]} />
        </a>
      </nav>
    </header>
  );
};
