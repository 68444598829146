import { FC } from "react";
import partenairesImage from "./assets/partenaires.png";
import { Footer, Header, Game } from "./components";
import "./styles/main.scss";

export const App: FC = () => {
  return (
    <div className="app">
      <Header />
      <main>
        <Game />
        <div className="infos">
          <h2 id="#projet">Les frères Miller</h2>
          <p>
            « Pin-Mou, Bas-Saint-Laurent, 1992. Shane et Bryan Miller
            comprennent pas pantoute comment leur sœur a disparu, c’était pas
            son premier saut de motocross au-dessus du lac Neigette pourtant… »
          </p>
          <p>
            Le projet «Les frères Miller» entremêle les codes narratifs de
            l’écriture, du jeu vidéo et de l’illustration proposant une
            expérience atypique de bande dessinée interactive soutenue par une
            trame sonore 100% originale.{" "}
          </p>
          <p>
            <p>
              Scénario : Julien Dallaire-Charest et Guillaume Proteau-Beaulieu
            </p>
            <p>Dessin et animation : Julien Dallaire-Charest</p>
            <p>Programmation : Alexandre Fortin </p>
            <p>Musique : Julien Babin </p>
            <p>
              Un projet présenté par St-Laurent Sachet et Québec BD, avec
              l'appui de l’Entente de développement culturel survenue entre la
              Ville de Québec et le ministère de la Culture et des
              Communications du Québec.{" "}
            </p>
            <p>
              Pour en savoir plus sur le développement du projet,{" "}
              <a
                href="https://juliendallairecharest.carbonmade.com/lesfreresmiller"
                target="_blank"
                rel="noreferrer"
              >
                cliquez ici
              </a>
            </p>

            <img
              className="infos__partners"
              src={partenairesImage}
              alt="St Laurent Sachet"
            />
          </p>
          <h2>Saint Laurent Sachet</h2>
          <p>
            Formé par Julien Dallaire-Charest et Guillaume Proteau-Beaulieu,
            St-Laurent Sachet mène de front les échanges entre les arts
            interactifs et la création de récits.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};
