import { FC } from "react";
import "./_footer.scss";

export const Footer: FC = () => {
  return (
    <footer className="footer">
      <p>
        © Tous droits réservés, St-Laurent Sachet. <br />
        Site conçu par{" "}
        <a href="https://kenmallar.com" target="_blank" rel="noreferrer">
          le beau Ken Mallar
        </a>
      </p>
    </footer>
  );
};
